import React from "react"
import HomeImage from "../images/source.jpg"
import LogoCss from "../images/logos/css-3_official.svg"
import LogoDrupal from "../images/logos/drupal.svg"
import LogoGatsby from "../images/logos/gatsby.svg"
import LogoGulp from "../images/logos/gulp.svg"
import LogoHtml from "../images/logos/html-5.svg"
import LogoJs from "../images/logos/javascript.svg"
import LogoMagento from "../images/logos/magento.svg"
import LogoNodejs from "../images/logos/nodejs.svg"
import LogoNpm from "../images/logos/npm.svg"
import LogoSass from "../images/logos/sass.svg"
import LogoWordpress from "../images/logos/wordpress.svg"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import LogoLinkedin from "../images/logos/linkedin.svg"
import LogoGithub from "../images/logos/github.svg"
import LogoTwitter from "../images/logos/twitter.svg"
import LogoInstagram from "../images/logos/instagram.svg"
import Sites from "../components/sites"

class IndexPage extends React.Component {
  render(props) {
    var settings = {
      speed: 5000,
      autoplay: true,
      autoplaySpeed: 0,
      centerMode: true,
      cssEase: 'linear',
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      infinite: true,
      initialSlide: 1,
      arrows: false,
      buttons: false
    };
    var settingsRTL = {
      speed: 5000,
      autoplay: true,
      autoplaySpeed: 0,
      centerMode: true,
      cssEase: 'linear',
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      infinite: true,
      initialSlide: 1,
      arrows: false,
      buttons: false,
      rtl: true
    };

    return (
      <div className="app"> 
        <div className="slide home">
          <div className="slide__image">
            <img src={ HomeImage } alt="Source code" />
          </div>
          <div className="slide__text">
            <h1>Hi, my name is Chris</h1>
            <p>I build engaging front ends for websites</p>
          </div>
        </div>
        <div className="slide about">
          <div className="slide__text">
            <h2>About Me</h2>
            <p>I'm Chris Wells, a frontend developer living in York and working in Leeds.</p>
            <p>I've worked with Magento, Drupal, Wordpress and a few other content management systems.</p>
            <p>I also have professional experience in modern workflow tooling, such as Node, npm and Gulp.</p>
            <p>You can see some of my work below, or follow me on:</p>
            <ul className="socials">
              <li><a href="https://www.linkedin.com/in/realchriswells/" target="_blank" rel="noopener noreferrer" title="LinkedIn"><img src={ LogoLinkedin } alt="LinkedIn" /></a></li>
              <li><a href="https://github.com/realchriswells/" target="_blank" rel="noopener noreferrer" title="Github"><img src={ LogoGithub } alt="Github" /></a></li>
              <li><a href="https://twitter.com/realchriswells" target="_blank" rel="noopener noreferrer" title="Twitter"><img src={ LogoTwitter } alt="Twitter" /></a></li>
              <li><a href="https://www.instagram.com/realchriswells/" target="_blank" rel="noopener noreferrer" title="Instagram"><img src={ LogoInstagram } alt="Instagram" /></a></li>
            </ul>
          </div>
          <div className="slide__logos">
            <Slider {...settings}>
              <div className="slider-item"><img src={ LogoMagento } alt="Magento" /></div>
              <div className="slider-item"><img src={ LogoDrupal } alt="Drupal" /></div>
              <div className="slider-item"><img src={ LogoGatsby } alt="Gatsby" /></div>
              <div className="slider-item"><img src={ LogoWordpress } alt="Wordpress" /></div>
            </Slider>
            <Slider { ...settingsRTL}>
              <div className="slider-item"><img src={ LogoCss } alt="CSS 3" /></div>
              <div className="slider-item"><img src={ LogoHtml } alt="HTML 5" /></div>
              <div className="slider-item"><img src={ LogoJs } alt="Javascipt" /></div>
            </Slider>
            <Slider {...settings}>
              <div className="slider-item"><img src={ LogoGulp } alt="Gulp" /></div>
              <div className="slider-item"><img src={ LogoSass } alt="Sass" /></div>
              <div className="slider-item"><img src={ LogoNodejs } alt="NodeJS" /></div>
              <div className="slider-item"><img src={ LogoNpm } alt="npm" /></div>
            </Slider>
            {/* <ul>
              <li><img src={ LogoCss } alt="CSS 3" /></li>
              <li><img src={ LogoHtml } alt="HTML 5" /></li>
              <li><img src={ LogoJs } alt="Javascipt" /></li>
            </ul>
            <ul>
              <li><img src={ LogoGulp } alt="Gulp" /></li>
              <li><img src={ LogoSass } alt="Sass" /></li>
              <li><img src={ LogoNodejs } alt="NodeJS" /></li>
              <li><img src={ LogoNpm } alt="npm" /></li>
            </ul> */}
          </div>
        </div>
        <div className="slide folio">
          <div class="slide__text">
            <h2>I've made some things</h2>
            <Sites />
          </div>
        </div>
      </div>
    )
  }
}

export default IndexPage