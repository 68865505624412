import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default props => (
  <StaticQuery
    query = {graphql`
      query {
        allFile(
          filter: {
            extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          }
        ) {
          edges {
            node {
              name
              childImageSharp{
                sizes(maxWidth: 1920) {
                  ...GatsbyImageSharpSizes_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render = { data => (
        <ul>
            {data.allFile.edges.map(image => (
                <li>
                    <div class="site">
                        <div class="site__image" data-height={image.node.childImageSharp.height}><Img key={image.node.childImageSharp.sizes.src} sizes={image.node.childImageSharp.sizes} /></div>
                        <div class="site__label">{image.node.name.replace(/_/g, ' ')}</div>
                    </div>
                </li>
            ))}
        </ul>
        
      
    )}
  />
)